import { useEffect, useMemo } from "react";

import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useGate } from "@nestoca/gate-react";
import { Flex, useBreakpointValue } from "@nestoca/ui";
import { useGetApplications } from "@shared/api/hooks/applications";
import {
  AUTH0_CLIENT,
  APPLICATION_STATE_GROUP,
  ACTIVE_APPLICATION_STATE,
  CLOSED_APPLICATION_STATE,
  FUNDED_APPLICATION_STATE,
} from "@shared/constants";
import {
  LayoutDesktopMobile,
  PERMISSIONS,
  UnauthorizedPage,
  withPermissions,
} from "@shared/ui";
import { useApplicationContext } from "@shared/ui/components/application-context";
import { ClippedContent } from "@shared/ui/components/clipped-content";
import { commonServerProps } from "@shared/utils/common-server-props";
import compose from "@shopify/react-compose";
import { GetServerSideProps } from "next";
import Head from "next/head";
import { useTranslation } from "react-i18next";

import { DashboardHighlightsGroup } from "@components/dashboard-highlights-group/dashboard-highlights-group";
import {
  ActiveTable,
  FundedTable,
  ClosedLostTable,
} from "@components/dashboard-table";
import { DashboardHeader } from "@components/header";
import { PrimarySidebarContent } from "@components/sidebar-content";
import { TableTabs } from "@components/table-tabs";
import { TableTab } from "@components/table-tabs/types";
import { useGetPageTitle } from "@hooks";
import { withApplication } from "@lib/with-application";
import { NextPageWithLayout } from "@pages/_app";
import { TablesProvider } from "@provider";

import styles from "./index.module.scss";

const Home = () => {
  const { t } = useTranslation("dashboard");

  const { isLoading: isLoadingAuth } = useAuth0();

  const { setApplication } = useApplicationContext();
  const gate = useGate();

  const isExternalBroker = gate.getRole() === "externalbroker";
  const isMobile = useBreakpointValue({ default: true, md: false });

  const { data: activeApplications } = useGetApplications({
    pageIndex: 0,
    pageSize: 1,
    filters: [
      {
        id: "currentStatus",
        value: [...ACTIVE_APPLICATION_STATE],
      },
    ],
    isExternalBroker,
  });

  const { data: fundedApplications } = useGetApplications({
    pageIndex: 0,
    pageSize: 1,
    filters: [
      {
        id: "currentStatus",
        value: [...FUNDED_APPLICATION_STATE],
      },
    ],
    isExternalBroker,
  });

  const { data: closedApplications } = useGetApplications({
    pageIndex: 0,
    pageSize: 1,
    filters: [
      {
        id: "currentStatus",
        value: [...CLOSED_APPLICATION_STATE],
      },
    ],
    isExternalBroker,
  });

  const tableTabs: TableTab[] = useMemo(
    () => [
      {
        id: APPLICATION_STATE_GROUP.ACTIVE_GROUP,
        index: 0,
        label: t("table.tab.active"),
        table: <ActiveTable />,
        applicationsCount: activeApplications?.totalResults || 0,
      },
      {
        id: APPLICATION_STATE_GROUP.FUNDED_GROUP,
        index: 1,
        label: t("table.tab.funded"),
        table: <FundedTable />,
        applicationsCount: fundedApplications?.totalResults || 0,
      },
      {
        id: APPLICATION_STATE_GROUP.CLOSED_GROUP,
        index: 2,
        label: isExternalBroker
          ? t("table.tab.declined")
          : t("table.tab.closedLost"),
        table: <ClosedLostTable />,
        applicationsCount: closedApplications?.totalResults || 0,
      },
    ],
    [
      t,
      activeApplications,
      fundedApplications,
      closedApplications,
      isExternalBroker,
    ]
  );

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setApplication({}), []);

  const pageTitle = useGetPageTitle();

  if (isLoadingAuth) return null;

  return (
    <>
      <Head>
        <title>{pageTitle}</title>
      </Head>
      <Flex direction="column" grow={1}>
        <DashboardHeader />
        <ClippedContent
          direction="column"
          gap={isExternalBroker && isMobile ? 4 : 6}
          className={styles["clipped-content"]}
        >
          <DashboardHighlightsGroup />
          <TablesProvider tableTabs={tableTabs}>
            <TableTabs />
          </TablesProvider>
        </ClippedContent>
      </Flex>
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  return commonServerProps({
    app: "hub",
    context,
    namespaces: ["applications", "common", "dashboard", "error", "form"],
    auth0Client: AUTH0_CLIENT.FINANCIAL_DASHBOARD,
  });
};

const Page = compose(
  withAuthenticationRequired,
  withPermissions({
    permissions: [PERMISSIONS.ACCESS_HUB],
    fallback: UnauthorizedPage,
  }),
  withApplication
)(Home) as NextPageWithLayout<never>;

Page.getLayout = (page) => {
  return (
    <LayoutDesktopMobile
      primarySidebarContent={<PrimarySidebarContent />}
      className={styles.layout}
    >
      {page}
    </LayoutDesktopMobile>
  );
};

export default Page;
